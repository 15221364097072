<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="text-muted mt-3 font-weight-bold font">
        {{$t('chainReactions.Inthispageyoucancreatereactions')}}
      </div>
    </b-alert>

    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$t('chainReactions.Chainreactions')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button
            @click="addNewTab()"
            variant="outline-primary"
            class="float-right"
            >{{$t('chainReactions.AddDeviceChainReaction')}}</b-button
          >
        </div>
      </div>
      <div class="card-body">
        <b-tabs content-class="mt-3" fill @activate-tab="fnActivateTab">
          <template v-for="(reactor_item, reactor_index) in reactors_available">
            <b-tab
              title="device name tab"
              v-bind:key="reactor_index"
              title-item-class="tab-title"
            >
              <template #title>
                <span class="float-left">{{ reactor_item.object }}</span>
                <div style="margin-left: 30px">
                  <button
                    type="button"
                    style="margin-top: -10px"
                    @click="promptRemoveLayout(reactor_item, reactor_index)"
                  >
                    <span>×</span>
                  </button>
                </div>
              </template>
              <!--begin::Example-->
              <!--end::Example-->
              <b-button
                @click="addNewCondition(reactor_item)"
                variant="outline-primary"
                >{{$t('chainReactions.Addcondition')}}</b-button
              >
              <br />
              <br />
              <b-button
                @click="addNewDeviceCondition(reactor_item)"
                variant="outline-primary"
                >{{$t('chainReactions.AddDevice')}}</b-button
              >
              <br />
              <br />
              <b-button
                @click="addNewReactionChain(reactor_item)"
                variant="outline-primary"
                >{{$t('chainReactions.AddDeviceToNotify')}}</b-button>

                
              <br />
              <br />
              <!-- <b-button @click="generateJSON">generate json</b-button>
              <br />
              <br /> -->
              <!-- <b-button @click="loadjson()">Load json</b-button>
              <br />
              <br /> -->
              <!-- <b-button @click="getFigures()">Get figures</b-button> -->
              <b-button @click="saveChanges(reactor_item)"
                >{{$t('chainReactions.Savechanges')}}</b-button
              >
              <!-- <b-button @click="loadRoutes()">rerun routes</b-button> -->

              <div
                :id="'canvas-' + reactor_item.device_id"
                class="item-canvas hidden"
              ></div>
            </b-tab>
          </template>
        </b-tabs>

        <div
                :id="'dvCanvaReactor'"
                class="item-canvas"
              ></div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
      </div>
    </div>
    <b-modal
      ref="new-chain-modal"
      id="new-chain-modal"
      title="Create chain starter"
      no-close-on-esc="true"
      no-close-on-backdrop="true"
      @ok="createChainStarter"
      @close="resetModal()"
      @cancel="resetModal()"
    >
      <div role="group">
        <label for="input-live">{{$t('chainReactions.Eventorigin')}}:</label>
        <b-form-select
          :disabled="!selectable_device"
          value-field="token"
          v-model="selectedEventOrigin"
          @change="populateProps()"
          text-field="object"
          :options="availableEvents"
          :state="selectedEventValidation"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Select the source of origin"
        ></b-form-select>

        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback id="input-live-feedback">
          {{$t('chainReactions.Nooriginselected')}}
        </b-form-invalid-feedback>

        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help"
          >{{$t('chainReactions.ThisIsTheOriginFromwillbelistened')}}</b-form-text
        >
      </div>

      <template v-if="!selectable_device || isNewDevice">
        <div role="group">
          <label for="input-live"
            ><b-spinner small v-show="loadingProps == true"></b-spinner
            >{{$t('chainReactions.Selectableprops')}}:</label
          >
          <b-form-select
            v-model="selectedPropOrigin"
            :options="possibleProps"
            text-field="label"
            :state="selectedPropValidation"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Select the prop of origin"
          ></b-form-select>

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            {{$t('chainReactions.Nopropselected')}}
          </b-form-invalid-feedback>

          <!-- This is a form text block (formerly known as help block) -->
          <b-form-text id="input-live-help"
            >{{$t('chainReactions.ThisIsPropWillTriggerChainEvent')}}</b-form-text
          >
        </div>

        <div role="group">
          <label for="input-live">{{$t('chainReactions.When')}}:</label>
          <b-form-select
            v-model="selectedWhenOrigin"
            :options="possibleWhen"
            text-field="label"
            :state="selectedWhenValidation"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Select a comparison operator"
          ></b-form-select>

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            {{$t('chainReactions.Nooperatorselected')}}
          </b-form-invalid-feedback>

          <!-- This is a form text block (formerly known as help block) -->
          <b-form-text id="input-live-help"
            >{{$t('chainReactions.ConditionWillBeTested')}}</b-form-text>
        </div>

        <b-form-group
          id="fieldset-1"
          description="Layout name"
          label="Give a value for the comparison"
          label-for="input-1"
          :state="state"
          :invalid-feedback="invalidFeedback"
          :valid-feedback="validFeedback"
        >
          <b-form-input
            id="input-1"
            v-model="valueToTest"
            :state="selectedValueToTestValidation"
            aria-describedby="layout-name-feedback"
            trim
          ></b-form-input>
          <b-form-invalid-feedback id="layout-name-feedback">
            {{$t('chainReactions.MakeSureWritePropper')}}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> {{$t('GLOBAL.Cancel')}} </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="ok()"
          :disabled="!validateFullForm"
        >
          {{$t('GLOBAL.OK')}}
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>

    <b-modal
      ref="new-destination-modal"
      id="new-destination-modal"
      title="Destination device"
      no-close-on-esc="true"
      no-close-on-backdrop="true"
      @ok="createLinkDestination()"
      @close="resetModal()"
      @cancel="resetModal()"
    >
      <div role="group">
        <label for="input-live">{{$t('chainReactions.Destinationdevice')}}:</label>
        <b-form-select
          value-field="token"
          v-model="selectedEventDestination"
          text-field="object"
          :options="availableEvents"
          :state="selectedDestinationValidation"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Select the source of destination"
        ></b-form-select>

        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback id="input-live-feedback">
          {{$t('chainReactions.Nodestinationselected')}}
        </b-form-invalid-feedback>

        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help"
          >{{$t('chainReactions.ThisDestinationDeviceToNotify')}}</b-form-text
        >
      </div>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> {{$t('GLOBAL.Cancel')}} </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="ok()"
          :disabled="!selectedDestinationValidation"
        >
          {{$t('GLOBAL.OK')}}
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>

<style>
.overlayMenu {
  position: absolute;
  cursor: pointer;
  font-size: 30px;
  color: red;
}
.item-canvas {
  padding: 0px;
  margin: 0px;
  border: 0px;
  position: absolute;
  top: 140px;
  right: 0px;
  left: 160px;
  bottom: 0px;
  overflow: scroll;
  background-color: #ffffff;
}

.draw2d_InputPort {
  fill: #f7f7f7;
  stroke: #d7d7d7;
}
.draw2d_OutputPort {
  fill: #f7f7f7;
  stroke: #d7d7d7;
}

.hidden {
  display: none;
}

.tab-title{
  /* font-weight: bold; */
  background-color: #dddd;
  border-radius: 11px 11px 0px 0px;
}

.tab-title .nav-link.active{
  font-weight: bold;
  background-color: #F3F6F9;
  border-radius: 11px 11px 0px 0px;
}
</style>

<script>
import $ from "jquery";
import draw2d from "draw2d";
import {
  SET_DEVICES,
  GET_DEVICES,
  FETCH_DEVICE_PROPS,
  FETCH_SINGLE_DEVICE,
  FORCE_MUTATE_DEVICES,
} from "@/core/services/store/devices.module";
import {
  FETCH_REACTORS,
  CREATE_REACTOR,
  UPDATE_REACTOR,
  DELETE_REACTOR,
} from "@/core/services/store/reactor.module";
import "@/core/plugins/draw2d/TableShape";
import "@/core/plugins/draw2d/mock";
import { FETCH_DEVICE_ACTIONS } from "@/core/services/store/devices.module";
export default {
  components: {},
  data() {
    return {
      view: null,
      availableEvents: [],
      selectedEventOrigin: null,
      selectedPropOrigin: null,
      possibleProps: [],
      expandedProps: [],
      possibleWhen: [
        { label: "bigger than", value: ">" },
        { label: "smaller than", value: "<" },
        { label: "equals", value: "=" },
        { label: "not equals", value: "!=" },
        { label: "contains", value: "contains" },
      ],
      selectedWhenOrigin: null,
      valueToTest: "",
      loadingProps: false,
      reactors_available: [],
      selectable_device: true,
      isNewDevice: false,
      taken_devices: [],
      canvas: [],
      selectedEventDestination: null,
      selected_canvas_device: null,
      currentCanvas: null
    };
  },
  computed: {
    validateFullForm() {
      if (this.selectable_device) {
        return this.selectedEventValidation;
      }
      return (
        this.selectedEventValidation &&
        this.selectedPropValidation &&
        this.selectedWhenValidation &&
        this.selectedValueToTestValidation
      );
    },
    selectedDestinationValidation() {
      return (
        this.selectedEventDestination != null &&
        this.selectedEventDestination.length > 3
      );
    },
    selectedEventValidation() {
      return (
        this.selectedEventOrigin != null && this.selectedEventOrigin.length > 3
      );
    },
    selectedPropValidation() {
      return (
        this.selectedPropOrigin != null && this.selectedPropOrigin.length > 2
      );
    },
    selectedWhenValidation() {
      return this.selectedWhenOrigin != null;
    },
    selectedValueToTestValidation() {
      return this.valueToTest != "";
    },
  },
  beforeDestroy() {
    $("#overlayMenu").remove();
    $(".overlayMenu").remove();
  },
  mounted() {
    // document.body.addEventListener("click", function (event) {
    //   if (event.target.tagName != "svg") {
    //     // this condition allows us to remove the -delete- icon
    //     // if theuser clicked outside the canvas
    //     $("#overlayMenu").remove();
    //   }
    // });
    FETCH_DEVICE_PROPS, FETCH_SINGLE_DEVICE;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_DEVICES) {
        this.availableEvents = [
          {
            label: "Online devices",
            options: state.devices.devices.filter((element) => {
              if (this.selectable_device === false) {
                return element.connection_type == 1;
              } else {
                return (
                  this.taken_devices.indexOf(element.token) == -1 &&
                  element.connection_type == 1
                );
              }
            }),
          },
          {
            label: "Offline devices",
            options: state.devices.devices.filter((element) => {
              if (this.selectable_device === false) {
                return (
                  element.connection_type == 0 ||
                  element.connection_type == null ||
                  element.connection_type == undefined
                );
              } else {
                return (
                  this.taken_devices.indexOf(element.token) == -1 &&
                  (element.connection_type == 0 ||
                    element.connection_type == null ||
                    element.connection_type == undefined)
                );
              }
            }),
          },
        ];
        // now push services
        this.availableEvents.push({
          label: "Cloud services",
          options: [
            { object: "Nest", token: "nest" },
            { object: "Acurite", token: "acurite" },
            { object: "Ecowitt", token: "ecowitt" },
          ],
        });
        this.availableEvents.push({
          label: "Nature events",
          options: [{ object: "Earthquake", token: "earthquake" }],
        });
      }
    });

    this.fetchReactors();
    /*
    there are several event starters for a chain reaction or initial events,
    CHAIN-STARTERs

    device.prop_value->
    device.action(assistant)->
    schedule->
    nest.prop_value->
    acurite.prop_value->
    earthquake->
    ecowitt->


    the previous starters can only call a CHAIN-LINK:
    ->device.action
    ->webhook
    ->email
    ->telegram
    ->twitter
    ->facebook messenger
    ->sms

    */

    window["draw2d"] = draw2d;

    // unmarshal the JSON document into the canvas
    // (load)
  },
  methods: {
    fnChangeCanvasView(indexToShow) {
      setTimeout(() => {
          this.currentCanvas.clear();
          var element = this.reactors_available[indexToShow];

          const reader = new draw2d.io.json.Reader();
                        reader.unmarshal(
                          this.currentCanvas,
                          JSON.parse(element.reactor)
                        );
        }, 100);
    }
    ,fnActivateTab(newTabIndex, prevTabIndex, bvEvent) {
      prevTabIndex;
      bvEvent;

      this.generateJSON().then(jsonText => {
        this.reactors_available[prevTabIndex].reactor=jsonText;

        this.fnChangeCanvasView(newTabIndex);
      })
      .catch(err => {
        console.log(err);
      });

    },
    addNewReactionChain(device) {
      console.log("canvas ", this.canvas);
      this.selected_canvas_device = device.id;
      // this.selectable_device = true;
      this.isNewDevice = false;
      this.$store.dispatch(FORCE_MUTATE_DEVICES);
      // block device selection
      this.selectedEventOrigin = device.token;
      this.$refs["new-destination-modal"].show();
    },
    getFigures() {
      console.log(this.view.getFigures());
    },
    resetModal() {
      this.selectedEventOrigin = null;
      this.selectable_device = true;
      this.isNewDevice = false;
      this.possibleProps = [];
      this.valueToTest = "";
      this.selectedWhenOrigin = null;
      this.valueToTest = "";
      console.log("resetting");
    },
    getAvailableEvents() {
      this.availableEvents
    },
    addNewCondition(item) {
      this.resetModal();
      this.selectable_device = false;
      this.isNewDevice = false;
      this.$store.dispatch(FORCE_MUTATE_DEVICES);
      // block device selection
      this.selectedEventOrigin = item.token;
      this.$refs["new-chain-modal"].show();
      this.populateProps();
    },
    addNewDeviceCondition(item) {
      item;
      this.resetModal();
      this.selectable_device = true;
      this.$store.dispatch(FORCE_MUTATE_DEVICES);
      // block device selection
      this.selectedEventOrigin = "";
      this.isNewDevice = true;
      this.$refs["new-chain-modal"].show();
      this.populateProps();
    },
    addNewTab() {
      this.resetModal();
      this.selectable_device = true;
      this.isNewDevice = false;
      this.$store.dispatch(FORCE_MUTATE_DEVICES);
      // block device selection
      this.selectedEventOrigin = "";
      this.$refs["new-chain-modal"].show();
      this.populateProps();
    },
    populateProps() {
      this.selectedPropOrigin = null;
      this.loadingProps = true;
      this.possibleProps = [];
      switch (this.selectedEventOrigin) {
        case "earthquake":
          break;
        case "nest":
        case "acurite":
        case "ecowitt":
          // download nest props
          // looks like a normal device
          this.$store
            .dispatch(FETCH_DEVICE_PROPS, {
              device_token: this.selectedEventOrigin,
            })
            // go to which page after successfully login
            .then((data) => {
              this.loadingProps = false;
              this.expandedProps = data;
              data.forEach((element) => {
                this.possibleProps.push({
                  label: element.command,
                  value: "prop:" + element.id,
                });
              });
            });
          break;
        default:
          // looks like a normal device
          this.$store
            .dispatch(FETCH_DEVICE_PROPS, {
              device_token: this.selectedEventOrigin,
            })
            // go to which page after successfully login
            .then((data) => {
              this.loadingProps = false;
              this.expandedProps = data;
              data.forEach((element) => {
                this.possibleProps.push({
                  label: element.command,
                  value: "prop:" + element.id,
                });
              });
            });
          break;
      }
    },
    generateJSON() {
      return new Promise((resolve, reject) => {
        try{
          const writer = new draw2d.io.json.Writer();

          writer.marshal(this.currentCanvas, (json) => {
            // convert the json object into string representation
            json.forEach((element) => {
              if (element.type == "TableShape") {
                // workaround to delete unwanted ports
                delete element.ports;
              }
            });
            
            let jsonTxt = JSON.stringify(json, null, 2);
            resolve(jsonTxt);
          });
        }
        catch(ex) {
          reject(ex);
        }
        
      });
      
    },
    addNewDeviceToExistingTab() {
      
      let device = this.availableEvents[0].options
        .filter((element) => element.token == this.selectedEventOrigin)
        .pop();

      if (device == null) {
        device = this.availableEvents[1].options
          .filter((element) => element.token == this.selectedEventOrigin)
          .pop();
      }

      let figure = eval("new TableShape();");
      figure.setName(device["object"]);
      figure.setDevice(device.token);
      const command = new draw2d.command.CommandAdd(
        this.currentCanvas,
        figure,
        30,
        30
      );
      this.currentCanvas
        .getCommandStack()
        .execute(command);
      // the role specifies if the figure is the one with the ports as output
      figure.role("starter");
      figure.setDeviceId(device.id);

      let full_prop = this.expandedProps
        .filter((element) => "prop:" + element.id == this.selectedPropOrigin)
        .pop();
      console.log(full_prop);
      full_prop["set_point"] = this.valueToTest;
      full_prop["operator"] = this.selectedWhenOrigin;
      full_prop["enableInput"] = false;
      full_prop["enableOutput"] = true;
      
      figure.addEntity(full_prop, false, true);

    },
    createChainStarter() {
      if(this.isNewDevice) {
        this.addNewDeviceToExistingTab();
      }
      else {
        // verify if the selected device is not already in use by another reaction chain
        if (this.taken_devices.indexOf(this.selectedEventOrigin) == -1) {
          this.createReactionChain();
        } else {
          // the device already contains a tab, so, son the existing one
          this.existingReactionChain();
        }
      }
      
    },
    createReactionChain() {
      // firstly send this to the cloud
      const creation = { token: this.selectedEventOrigin };

      this.$store.dispatch(CREATE_REACTOR, creation).then((data) => {
        data;
        this.fetchReactors();
      });
    },
    existingReactionChain() {
      let deviceSelected = this.availableEvents.find(fnd=> fnd.options.filter(fopt=>fopt.token==this.selectedEventOrigin).length > 0);
      

      // let device = this.availableEvents[0].options
      //   .filter((element) => element.token == this.selectedEventOrigin)
      //   .pop();
      let device = null;

      if(deviceSelected) {
        device = deviceSelected.options
        .filter((element) => element.token == this.selectedEventOrigin)
        .pop();
      }

      console.log("device ", device);

      if (device == null) {
        device = this.availableEvents[1].options
          .filter((element) => element.token == this.selectedEventOrigin)
          .pop();
      }
      console.log(this.expandedProps);
      let full_prop = this.expandedProps
        .filter((element) => "prop:" + element.id == this.selectedPropOrigin)
        .pop();
      console.log(full_prop);
      full_prop["set_point"] = this.valueToTest;
      full_prop["operator"] = this.selectedWhenOrigin;
      full_prop["enableInput"] = false;
      full_prop["enableOutput"] = true;
      // as a first step, lets find the figureof the device
      // if there is no figure, then is created otherwise only
      // the output port is created
      console.log(this.currentCanvas.getFigures());
      let figure = null;
      figure = this.currentCanvas
        .getFigures()
        .data.filter((element) => {
          console.log(element);
          if (!!element.userData && !!element.userData.role) {
            return element.userData.role == "starter";
          }
        })
        .pop();
      if (figure == undefined) {
        figure = eval("new TableShape();");
        figure.setName(device["object"]);
        figure.setDevice(device.token);
        const command = new draw2d.command.CommandAdd(
          this.currentCanvas,
          figure,
          30,
          30
        );
        this.currentCanvas.getCommandStack().execute(command);
        // the role specifies if the figure is the one with the ports as output
        figure.role("starter");
      }
      figure.addEntity(full_prop, false, true);
      // console.log(figure.getTest());
    },
    generateTable() {
      let figure = eval("new TableShape();");
      console.log(figure);
      figure.addEntity("id");
      figure.setName("NewTable");
      //     // create a command for the undo/redo support
      const command = new draw2d.command.CommandAdd(
        this.view,
        figure,
        255,
        246
      );
      console.log(figure.getTest());
      this.view.getCommandStack().execute(command);
    },
    loadjson() {
      const reader = new draw2d.io.json.Reader();
      reader.unmarshal(this.view, window["jsonDocument"]);
    },
    loadRoutes() {
      const reader = new draw2d.io.json.Reader();
      reader.unmarshal(this.view, window["jsonDocument2"]);
    },
    createLinkDestination() {
      let device = this.availableEvents[0].options
        .filter((element) => element.token == this.selectedEventDestination)
        .pop();
      if (device == null) {
        device = this.availableEvents[1].options
          .filter((element) => element.token == this.selectedEventDestination)
          .pop();
      }
      let figure = eval("new TableShape();");
      figure.setName(device["object"]);
      figure.setDevice(device.token);
      const command = new draw2d.command.CommandAdd(
        this.currentCanvas,
        figure,
        30,
        30
      );
      this.currentCanvas
        .getCommandStack()
        .execute(command);
      // the role specifies if the figure is the one with the ports as output
      figure.role("link");
      // here we invoke all the actions of the device to add them
      this.$store
        .dispatch(FETCH_DEVICE_ACTIONS, { device_token: device.token })
        // go to which page after successfully login
        .then((data) => {
          // download devices list
          console.log(data);
          data.forEach((element) => {
            if (element.is_only_question == 1 || element.is_only_question == null) {
              let full_prop = element;
              full_prop["set_point"] = "";
              full_prop["operator"] = "";
              full_prop["enableInput"] = true;
              full_prop["enableOutput"] = false;
              figure.addEntity(full_prop, false, true);
            }
          });
        });
    },
    saveChanges(device) {
      console.log(device);
      const writer = new draw2d.io.json.Writer();
      writer.marshal(this.currentCanvas, (json) => {
        // convert the json object into string representation
        json.forEach((element) => {
          if (element.type == "TableShape") {
            // workaround to delete unwanted ports
            delete element.ports;
          }
        });

        if(json && json.length > 0 && json[0].type=="TableShape" 
            && (json[0].userData.deviceId ==null || json[0].userData.deviceId ==undefined)
        ) {
          json[0].userData.deviceId = device.id;
        }

        let arrDeviceIds = [];

        if(json.filter(flt=>flt.type=="TableShape").length > 0) {
          arrDeviceIds = json.filter(flt=>flt.type=="TableShape").map((val) => {
                    return val.userData.deviceId;
                  });
        }
        

        let jsonTxt = JSON.stringify(json, null, 2);
      
        // invoke the saving process
        this.$store
          .dispatch(UPDATE_REACTOR, { id: device.id, layout: jsonTxt,devicesIds: arrDeviceIds.join() })
          .then((response) => {
            response;
          });
      });
    },
    promptRemoveLayout(reactor_item, indexToDelete){
      this.$store
          .dispatch(DELETE_REACTOR, { id: reactor_item.id })
          .then((response) => {
            response;
            //

            if(indexToDelete > 0)
            {
              this.fnChangeCanvasView(0);
              var ixn =  this.taken_devices.indexOf(reactor_item.token);

              if(ixn>=0) {
                this.taken_devices.splice(ixn,1);
              }

              this.reactors_available.splice(indexToDelete,1);
            }
            else {
                //this.fetchReactors();
                this.currentCanvas.clear();
            }
            
          });
    },
    fetchReactors() {
      this.$store.dispatch(FETCH_REACTORS).then((reactors) => {
        this.reactors_available = reactors;
        this.taken_devices = this.reactors_available.map(
          (element) => element.token
        );
        this.$store.dispatch(GET_DEVICES);
        setTimeout(() => {
          if(this.reactors_available.length > 0) {

            if(this.currentCanvas == null) {
              this.create2Canvas(this.reactors_available[0].device_id);
            }
            
          }
          
        }, 1200);
      });
    },
    create2Canvas(device_id) {
      var element = this.reactors_available.find(fnd=> fnd.device_id==device_id);

      this.currentCanvas = new draw2d.Canvas("dvCanvaReactor");

            const routerToUse =
              new draw2d.layout.connection.InteractiveManhattanConnectionRouter();
            this.currentCanvas.installEditPolicy(
              new draw2d.policy.connection.DragConnectionCreatePolicy({
                createConnection: function () {
                  let connection = new draw2d.Connection({
                    stroke: 3,
                    outlineStroke: 1,
                    outlineColor: "#303030",
                    color: "91B93E",
                    router: routerToUse,
                  });
                  return connection;
                },
              })
            );
            if (
              element.reactor != undefined &&
              element.reactor != null &&
              element.reactor != ""
            ) {
              const reader = new draw2d.io.json.Reader();
              reader.unmarshal(
                this.currentCanvas,
                JSON.parse(element.reactor)
              );
            }
    }
  }
};
</script>
