
window["jsonDocument"] = [
  {
    "type": "TableShape",
    "id": "3253ff2a-a920-09d5-f033-ca759a778e19",
    "x": 419,
    "y": 104,
    "width": 116.390625,
    "height": 123.53125,
    "alpha": 1,
    "selectable": true,
    "draggable": true,
    "angle": 0,
    "userData": {"token":"2a3b78d2"},
    "cssClass": "TableShape",
    "bgColor": "rgba(219,221,222,1)",
    "color": "rgba(215,215,215,1)",
    "stroke": 1,
    "radius": 3,
    "dasharray": null,
    "gap": 0,
    "name": "Escalera uno",
    "entities": [
      {
        "text": "Turn on all",
        "id": "e97f6f8a-4306-0667-3a95-0a5310a2c15c",
        "user_data": {
          "unit": "°C",
          "command": "Turn on all",
          "id": 1238,
          "value": "",
          "set_point": "",
          "operator": "",
          "command_data": "on",
          "enableInput": true,
          "enableOutput": true,
          "port_id": "45a1fe54-7139-eb80-9af3-8dcdba917413"
        }
      },
      {
        "text": "Turn on button 1",
        "id": "357e132c-aa47-978f-a1fa-d13da6736989",
        "user_data": {
          "unit": "°C",
          "command": "Button 1",
          "id": 1238,
          "command_data": "bt1",
          "value": "",
          "set_point": "",
          "operator": "",
          "enableInput": true,
          "enableOutput": true,
          "port_id": "45a1fe54-7139-eb80-9af3-8dcdba917413"
        }
      },
      {
        "text": "Turn on button 2",
        "id": "a2156a71-f868-1f8f-e9a1-b185dbdfc3de",
        "user_data": {
          "unit": "°C",
          "command": "Button 2",
          "id": 1238,
          "command_data": "bt2",
          "value": "",
          "set_point": "",
          "operator": "",
          "enableInput": true,
          "enableOutput": true,
          "port_id": "45a1fe54-7139-eb80-9af3-8dcdba917413"
        }
      },
      {
        "text": "Turn on button 3",
        "id": "8d410fef-5c6e-286d-c9c3-c152d5bd9c52",
        "user_data": {
          "unit": "°C",
          "command": "Button 3",
          "id": 1238,
          "command_data": "bt3",
          "value": "",
          "set_point": "",
          "operator": "",
          "enableInput": true,
          "enableOutput": true,
          "port_id": "45a1fe54-7139-eb80-9af3-8dcdba917413"
        }
      }
    ]
  },
  {
    "type": "TableShape",
    "id": "290ce47a-73d0-6287-f3d0-a3cbe190f1e6",
    "x": 103,
    "y": 128,
    "width": 116.390625,
    "height": 59,
    "alpha": 1,
    "selectable": true,
    "draggable": true,
    "angle": 0,
    "userData": {
      "token": "26d15c48","role":"starter"
    },
    "cssClass": "TableShape",
    "bgColor": "rgba(219,221,222,1)",
    "color": "rgba(215,215,215,1)",
    "stroke": 1,
    "radius": 3,
    "dasharray": null,
    "gap": 0,
    "name": "Thermostat",
    "entities": [
      {
        "text": "Temperature > 12",
        "id": "65b1361a-3aea-c90d-7040-496a5b9ed05e",
        "user_data": {
          "unit": "°C",
          "command": "Temperature",
          "id": 1238,
          "command_data": "temp",
          "value": "28.01",
          "set_point": "12",
          "operator": ">",
          "enableInput": true,
          "enableOutput": true
        }
      }
    ]
  },
  {
    "type": "draw2d.Connection",
    "id": "7f590d0b-3c5e-8872-65ac-c95b048dd5b8",
    "alpha": 1,
    "selectable": true,
    "draggable": true,
    "angle": 0,
    "userData": {},
    "cssClass": "draw2d_Connection",
    "stroke": 3,
    "color": "rgba(145,185,62,1)",
    "outlineStroke": 1,
    "outlineColor": "rgba(48,48,48,1)",
    "policy": "draw2d.policy.line.OrthogonalSelectionFeedbackPolicy",
    "vertex": [
      {
        "x": 219.390625,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 151.28125
      },
      {
        "x": 419,
        "y": 151.28125
      }
    ],
    "router": "draw2d.layout.connection.InteractiveManhattanConnectionRouter",
    "radius": 3,
    "routingMetaData": {
      "routedByUserInteraction": false,
      "fromDir": 1,
      "toDir": 3
    },
    "source": {
      "node": "290ce47a-73d0-6287-f3d0-a3cbe190f1e6",
      "port": "output_65b1361a-3aea-c90d-7040-496a5b9ed05e"
    },
    "target": {
      "node": "3253ff2a-a920-09d5-f033-ca759a778e19",
      "port": "input_e97f6f8a-4306-0667-3a95-0a5310a2c15c"
    }
  },
  {
    "type": "draw2d.Connection",
    "id": "fb2c4d6a-f828-ab11-d656-f692b351d3eb",
    "alpha": 1,
    "selectable": true,
    "draggable": true,
    "angle": 0,
    "userData": {},
    "cssClass": "draw2d_Connection",
    "stroke": 3,
    "color": "rgba(145,185,62,1)",
    "outlineStroke": 1,
    "outlineColor": "rgba(48,48,48,1)",
    "policy": "draw2d.policy.line.OrthogonalSelectionFeedbackPolicy",
    "vertex": [
      {
        "x": 219.390625,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 194.28125
      },
      {
        "x": 419,
        "y": 194.28125
      }
    ],
    "router": "draw2d.layout.connection.InteractiveManhattanConnectionRouter",
    "radius": 3,
    "routingMetaData": {
      "routedByUserInteraction": false,
      "fromDir": 1,
      "toDir": 3
    },
    "source": {
      "node": "290ce47a-73d0-6287-f3d0-a3cbe190f1e6",
      "port": "output_65b1361a-3aea-c90d-7040-496a5b9ed05e"
    },
    "target": {
      "node": "3253ff2a-a920-09d5-f033-ca759a778e19",
      "port": "input_a2156a71-f868-1f8f-e9a1-b185dbdfc3de"
    }
  },
  {
    "type": "draw2d.Connection",
    "id": "5e8e1cd9-69d9-da10-13d7-50b093a0af1e",
    "alpha": 1,
    "selectable": true,
    "draggable": true,
    "angle": 0,
    "userData": {},
    "cssClass": "draw2d_Connection",
    "stroke": 3,
    "color": "rgba(145,185,62,1)",
    "outlineStroke": 1,
    "outlineColor": "rgba(48,48,48,1)",
    "policy": "draw2d.policy.line.OrthogonalSelectionFeedbackPolicy",
    "vertex": [
      {
        "x": 219.390625,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 175.25
      },
      {
        "x": 319.1953125,
        "y": 194.28125
      },
      {
        "x": 419,
        "y": 194.28125
      }
    ],
    "router": "draw2d.layout.connection.InteractiveManhattanConnectionRouter",
    "radius": 3,
    "routingMetaData": {
      "routedByUserInteraction": false,
      "fromDir": 1,
      "toDir": 3
    },
    "source": {
      "node": "290ce47a-73d0-6287-f3d0-a3cbe190f1e6",
      "port": "output_65b1361a-3aea-c90d-7040-496a5b9ed05e"
    },
    "target": {
      "node": "3253ff2a-a920-09d5-f033-ca759a778e19",
      "port": "input_a2156a71-f868-1f8f-e9a1-b185dbdfc3de"
    }
  }
];